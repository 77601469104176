// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; import './autoload/map.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';
import 'select2';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

//inputs labels
function moveLabel(element) {
  //check for checkbox
  if (
    !element.parents('.gfield_checkbox').length &&
    !element.parents('.gfield').hasClass('static-label') &&
    !element.hasClass('datepicker')
  ) {
    element.parents('.gfield').addClass('active');
    let fontSize = 13;

    let $label = element.parents('.gfield').find('label');
    $label
      .animate(
        {
          bottom: '58px',
          left: '0',
          'font-size': fontSize + 'px',
        },
        300
      )
      .addClass('active');
  }
}

var scrollOut;
function returnLabel(element) {
  if (
    !element.val() &&
    !element.parents('.gfield_checkbox').length &&
    !element.parents('.gfield').hasClass('static-label') &&
    !element.hasClass('datepicker')
  ) {
    let fontSize = 25;
    if ($(window).width() < 768) fontSize = 18;

    element.parents('.gfield').removeClass('active');
    let $label = element.parents('.gfield').find('label');

    $label
      .animate(
        {
          bottom: '8px',
          left: '10px',
          'font-size': fontSize + 'px',
        },
        500
      )
      .removeClass('active');
  }
}

let floatingPageScroll = 0;
function toggleStatic() {
  let $body = $('body'),
    $menu = $('.menu-icon');
  if ($menu.hasClass('is-active')) {
    floatingPageScroll = $(window).scrollTop();
    setTimeout(function () {
      $body.addClass('st');
    }, 200);
  } else {
    $('body').removeClass('st');
    $('html, body').animate(
      {
        scrollTop: floatingPageScroll,
      },
      0
    );
  }
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('select').select2();

  $(document).on('click', '.menu-icon', function () {
    if ($(window).width() < 768) toggleStatic();
  });

  $('.testimonials-slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    pauseOnHover: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.videos-slider').slick({
    cssEase: 'ease',
    fade: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    pauseOnHover: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 0,
  });

  $('.gfield input, .gfield textarea').each(function () {
    if ($(this).val()) {
      moveLabel($(this));
    }
  });

  $(document).on('focus', '.gfield input, .gfield textarea', function () {
    moveLabel($(this));
  });
  $(document).on('focusout', '.gfield input, .gfield textarea', function () {
    returnLabel($(this));
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * Careers page vacation details toggle
   */
  $(document).on('click', '.show-details', function () {
    $(this).parents('.career').find('.career-details').slideToggle();
  });

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  scrollOut = ScrollOut({
    threshold: 0.01,
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();

    $('select').select2();

    $('.gfield input, .gfield textarea').each(function () {
      if ($(this).val()) {
        moveLabel($(this));
      }
    });
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  function checkIcon() {
    if ($(window).scrollTop() === 0) {
      $('.menu-icon').hasClass('is-active')
        ? $('header').addClass('scr')
        : $('header').removeClass('scr');
    }
  }

  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');

      checkIcon();
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');

      checkIcon();

      if ($(window).width() < 768) toggleStatic();
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  if (scrollOut) scrollOut.update();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  let y = $(window).scrollTop(),
    $header = $('.header');

  if (y) {
    if (!$header.hasClass('scr')) $header.addClass('scr');
  } else {
    if ($header.hasClass('scr')) $header.removeClass('scr');
  }
});
